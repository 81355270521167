import React from 'react'
import Layout from '../../components/layouts/courseLandingLayout'
import styled from 'styled-components';
import Button from '../../components/buttons/linkButton';
import { Container, Col, Row } from 'react-bootstrap';
import Navbar from '../../components/navbars/courseLandingNavbarNoMenu';
import ReactGA from 'react-ga'

const HeroBackground = styled.div`
  display: block;
  background: none;
  background-size: cover;
  background-position: center center;
  background-color: var(--blue);
`

const HeroContainer = styled(Container)`
  padding-top: 150px;
  padding-bottom: 150px;
  height: 100%;
  h1 {
    color: var(--white);
    text-align: center;
  }
  h5 {
    color: var(--white);
    padding-top: 15px;
    font-family: 'roboto-mono';
  }
  img {
    border: 4px solid var(--black);
    box-shadow: -15px 15px var(--black);
  }
  p {
    color: var(--white);
    text-align: center;
    margin-top: 10px;
    font-size: 16px;
  }

  @media screen and (max-width: 767px) {
    h1, p {
      color: var(--white);
    }

    .cta-hero-invite {
      margin-bottom: 20px;
    }
  } 
`

const VideoContainer = styled(Container)`
  margin-top: -200px;
  padding-top: 80px;
  padding-bottom: 60px;
  max-width: 800px; 
  h3 {
    text-align: center;
  }
  .cta-container {
    text-align: center;
  }
  img {
    border: 4px solid var(--black);
    box-shadow: -15px 15px var(--black);
  }
  .capacity-container {
    p {
      margin-bottom: 5px;
      font-size: 16px;
      text-align: center;
    }
  }
`

const FocusWebinar = ({location}) => {
  const fireGTMEvent = () => {
    ReactGA.event({
      category: 'CTA - Button',
      action: 'Click',
      label: 'waitlist',
    })
  };

  return (
    <Layout>
      <HeroBackground>
        <Navbar location={location}/>
        <HeroContainer>
          <Row>
            <Col md={12}>
              <h1>How to Focus When Learning to Code Webinar_</h1>
            </Col>
          </Row>
        </HeroContainer>
      </HeroBackground>
      <section>
        <VideoContainer>
          <Row>
            <Col className='video-left' md={12}>
              <iframe title='focus' width="100%" height="435" src="https://www.youtube.com/embed/PX9smb_h6gs" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen='true'></iframe>
              <div className='cta-container'>
                <a href='https://f.hubspotusercontent30.net/hubfs/8389530/how-to-focus-when-learning-to-code.pdf' target='_blank' rel="noreferrer">
                  <Button fireGTMEvent={fireGTMEvent} handleCTAClick={() => {}} text={'Download Slidedeck PDF'} />
                </a>
              </div>
            </Col>
          </Row>
        </VideoContainer>
      </section>
    </Layout>
  )
}

export default FocusWebinar
